import React from "react"

// Components
import Layout from "../components/layout/layout"
import SEO from "../components/helmet/seo"
import Contact from "../components/page-partials/contact"
import ContactForm from "../components/page-partials/form"

// Markup
const ContactPage = () => {
  return (
    <Layout mainColor="#000814" styleClass="disabled-footer contact">
      <SEO title="Dis-moi tout"/>
      <div className="split-page">
        <Contact />
        <ContactForm />
      </div>
    </Layout>
  )
}
export default ContactPage
