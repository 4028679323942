import React from "react"

// Components
import Footer from "../layout/footer"
import Back from "../partials/svg/back"

// Markup
const Contact = () => {
  return (
    <div id="contact">
      <Back url="/" styleClass="back-link--black" text="accueil"/>
      <div className="container">
        <section className="main-page-content">
          <h1>Dis-moi tout</h1>
          <p>Une question, quelque chose à partager ou un projet web à développer ?</p>
        </section>
      </div>
      <Footer />
    </div>
  )
}
export default Contact
