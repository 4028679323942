import React from "react"

// Components

// Markup
const ContactForm = () => {
  return (
    <div id="contact-form">
        <form className="contact-form" name="contact" method="post" action="/success" data-netlify="true" data-netlify-honeypot="bot-field">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact"/>
          <input type="hidden" name="subject" value="Nouveau message du formulaire jean-christio.ch" />
          <div className="contact-form-fields">
            <div className="contact-form-user">
              <div className="contact-form-user__lastname">
                <label htmlFor="nom">Nom</label>
                <input id="nom" type="text" name="nom" placeholder="Nom" required/>
              </div>
              <div className="contact-form-user__firstname">
                <label htmlFor="prenom">Prénom</label>
                <input id="prenom" type="text" name="prenom" placeholder="Prénom" required/>
              </div>
            </div>
            <div className="contact-form-email">
              <label htmlFor="email">Email</label>
              <input id="email" type="email" name="email" placeholder="email@exemple.ch" required/>
            </div>
            <div className="contact-form-body">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="4" cols="30" placeholder="Votre texte..." required></textarea>
            </div>
          </div>
          <div className="contact-form-submit">
            <button type="submit" name="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
              envoyer
            </button>
          </div>
      </form>
    </div>
  )
}
export default ContactForm
